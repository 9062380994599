import { DeleteFilled, DownOutlined } from "@ant-design/icons";
import React from "react";
import TableData from "../table-data";
import { Icon } from "src/components/atoms/icons";
import TextInput from "src/components/atoms/text-input";

const TableRowComponent = ({
  title,
  data,
  chargeIndex,
  expandedRows,
  charge,
  toggleRow,
  isExpandable,
  detailIndex,
  detail,
  showDelete,
  showAddMoreBtn,
  isEditMode,
  handleInputChange,
  handleTitleInputChange,
  handleDeleteCharge,
  addPricingLine,
  getTotalChargesById,
  stickyColClassName,
}: any) => {
  if (isExpandable) {
    return (
      <tr
        key={detailIndex}
        className={`${expandedRows[chargeIndex] ? "" : "hidden"}`}
      >
        <td
          className={`${stickyColClassName} py-8  text-start font-medium text-primary-400`}
          style={{
            position: "sticky",
            left: 0,
            zIndex: 100,
            width: "400px", // Fixed width for the first column
          }}
        >
          <div className="w-[400px] w-full pl-25 text-12 font-normal leading-18">
            {charge?.isEditable && isEditMode ? (
              <div className="flex w-full">
                {" "}
                <TextInput
                  classes={{
                    containerClassName: "w-[80%]",
                    inputClassName: "normal-input",
                  }}
                  type="text"
                  value={detail?.charge_description || detail?.name}
                  required
                  name=""
                  onChange={(e: any) =>
                    handleTitleInputChange({
                      value: e.target.value,
                      chargeId: charge?.id,
                      subChargeId: detail?.id,
                    })
                  }
                />
              </div>
            ) : (
              <> {detail?.charge_description}</>
            )}
          </div>
        </td>
        {detail.values.map((value: any, valueIndex: any) => (
          <TableData
            chargeId={charge?.id}
            subChargeId={detail?.id}
            inputIndex={valueIndex}
            key={valueIndex}
            className=""
            value={value}
            // editable={detail?.isEditable && isEditMode}
            editable={isEditMode}
            onChange={handleInputChange}
          />
        ))}

        <TableData
          className="!w-[100px] !justify-end"
          key={"colIndex"}
          value={"EUR"}
          // editable={detail?.isEditable && isEditMode}
          editable={false}
        />
        {/* {showDelete && detail?.isEditable && ( */}
        {showDelete && (
          <td
            className={`${stickyColClassName} py-10  text-start font-semibold`}
            style={{
              position: "sticky",
              right: 0,
              zIndex: 10,
              // width: "40px", // Fixed width for the first column
            }}
          >
            <div
              className={`flex items-center justify-end ${stickyColClassName}`}
            >
              <button
                onClick={() => {
                  handleDeleteCharge({
                    chargeId: charge?.id,
                    subChargeId: detail?.id,
                  });
                }}
                className="flex cursor-pointer items-center disabled:cursor-not-allowed"
              >
                <Icon.McsIcDelete />
              </button>
            </div>
          </td>
        )}
      </tr>
    );
  }
  return (
    <tr className="cursor-pointer" onClick={() => toggleRow(chargeIndex)}>
      <td
        className={`cursor-pointer ${stickyColClassName}  text-start font-medium text-primary-600`}
        style={{
          position: "sticky",
          left: 0,
          zIndex: 10,
          width: "400px", // Fixed width for the first column
        }}
      >
        <div className="w-[400px] py-10">
          <button
            // className={`transition-transform duration-300 ${expandedRows[chargeIndex] ? "rotate-180" : "rotate-0"} mr-10`}
            className={` ${expandedRows[chargeIndex] ? "rotate-180" : "rotate-0"} mr-10`}
          >
            <DownOutlined />
          </button>
          <span className="text-14 font-medium leading-20 text-primary-600">
            {" "}
            {charge.category} {" Charges"}
          </span>
        </div>
      </td>
      {data.columns.map((_: any, colIndex: any) => (
        <TableData
          className="font-bold"
          key={colIndex}
          value={getTotalChargesById(charge?.id, colIndex)}
        />
      ))}

      <td
        key={"colIndex"}
        className="text-end font-inter text-12 font-normal leading-18 text-primary-400"
        style={{ width: "150px" }}
      >
        <div className="flex w-[120px] justify-end font-bold">EUR</div>
      </td>
    </tr>
  );
};

export default TableRowComponent;
