import React, { useEffect, useState } from "react";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomDrawer from "src/components/molecules/custom-drawer";
import useCustomDrawer from "src/hooks/useCustomDrawer";
import { TABS } from "src/pages/quotations/elements/quotation-tabs/types";
import "./style.css";
import {
  formatContainerTypeAndSizeForPreview,
  getTermNameFromValue,
  isAdmin,
  isCustomQuotation,
} from "src/helpers";
import { MAILING_ADDRESS } from "src/types";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import debounce from "lodash/debounce";
import useValidCharges from "src/hooks/useValidCharges";
import { useLocation } from "react-router-dom";

const SRPreviewFclComponent = () => {
  const { formikQuotationFcl } = useQuotationsContextFcl();

  const { pathname } = useLocation();
  const isValidCharges = useValidCharges(
    formikQuotationFcl?.values?.chargesData,
  );

  useEffect(() => {
    const validateDebounced = debounce(() => {
      formikQuotationFcl.validateForm();
    }, 300); // Adjust the delay (in milliseconds) as needed

    validateDebounced();

    // Cleanup the debounce on unmount
    return () => validateDebounced.cancel();
  }, [formikQuotationFcl.values]);

  const [data, setData] = useState([
    {
      title: "Service Type",
      value: "FCL",
    },
    {
      title: "Origin",
      value: "-",
    },
    {
      title: "Destination",
      value: "-",
    },
    {
      title: "Terms",
      value: getTermNameFromValue(formikQuotationFcl?.values?.terms),
    },
    {
      title: "Container type and size",
      value: "-",
      error: (
        <span className="text-14 font-medium leading-20 text-helper-text "></span>
      ),
    },
  ]);

  useEffect(() => {
    setData([
      {
        title: "Service Type",
        value: "FCL",
      },
      {
        title: "Origin",
        value: formikQuotationFcl?.values?.origin_port || "-",
      },
      {
        title: "Destination",
        value: formikQuotationFcl?.values?.destination_port || "-",
      },
      {
        title: "Terms",
        value: getTermNameFromValue(formikQuotationFcl?.values?.terms),
      },
      {
        title: "Container type and size",
        value: formatContainerTypeAndSizeForPreview(
          formikQuotationFcl?.values?.cargo_item_specs,
        ),
        error: (
          <span className="text-14 font-medium leading-20 text-helper-text "></span>
        ),
      },
    ]);
  }, [
    formikQuotationFcl?.values?.terms,
    formikQuotationFcl?.values?.cargo_item_specs,
    formikQuotationFcl?.values?.origin_port,
    formikQuotationFcl?.values?.destination_port,
  ]);

  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get("tab") ?? "";
  const { isOpen, toggle } = useCustomDrawer();

  console.log(formikQuotationFcl?.values, "values");

  return (
    <div
      className={
        tabValue === TABS.HISTORY.value
          ? "hidden"
          : "fixed bottom-0 left-0 right-0 z-[150] md:left-[250px]"
      }
      id="sr-preview"
    >
      <CustomDrawer
        isOpen={isOpen}
        toggle={toggle}
        header={
          <div
            className="inline-flex rounded-tl-[5px] rounded-tr-[5px] bg-primary-100 text-16 font-medium leading-24"
            onClick={toggle}
          >
            <span className="px-10 py-5"> Shipment Request Preview</span>
            <button className="flex w-[85px] items-center justify-center px-10">
              <i className={`${isOpen ? "" : "rotate-[180deg]"} `}>
                <Icon.MscIcChevronDown />
              </i>
              <div className="text-primary-400">{isOpen ? "Hide" : "Show"}</div>
            </button>
          </div>
        }
      >
        <div className="flex flex-col gap-y-3 bg-primary-100 px-12 py-12">
          <div className="flex flex-col items-end justify-between gap-2 lg:flex-row">
            <div className="flex flex-wrap  gap-4 rounded-[10px] bg-primary-50 px-24 py-12">
              {data?.map((d, i) => {
                return (
                  <div className={"flex min-w-[106px] flex-col gap-1"} key={i}>
                    <p className="text-14 font-medium leading-20 text-gray-400">
                      {d.title}
                    </p>
                    <p>{d.value}</p>
                    {d?.error}
                  </div>
                );
              })}
            </div>
            <div className="">
              <CustomButton
                onClick={() => {
                  formikQuotationFcl.submitForm();
                }}
                variant="primary"
                disabled={
                  isAdmin(pathname)
                    ? !isCustomQuotation(formikQuotationFcl)
                      ? !formikQuotationFcl?.isValid
                      : !formikQuotationFcl?.isValid || !isValidCharges
                    : !formikQuotationFcl?.isValid
                }
              >
                {"Generate Quotation"}
              </CustomButton>
            </div>
          </div>
          <div className="flex items-center gap-x-1  text-gray-500-base">
            <span>
              <Icon.McsIcWarning />
            </span>
            <span>
              You can only request quotes for FAK (Freight of All Kinds). For
              dangerous goods or other kinds of freight, kindly{" "}
              <a
                className="text-14 leading-28 text-blue-1 underline hover:text-blue-300 hover:underline"
                href={`mailto:${MAILING_ADDRESS}`}
              >
                email us.
              </a>
            </span>
          </div>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default SRPreviewFclComponent;
